<template>
  <div>
    <div class="outerContainer" id="outerContainerIntro">
      <div class="innerContainer" id="introContent" v-if="currentPage === 0">
        <div id="videoContainer">
          <iframe
            src="https://www.youtube.com/embed/iZL1S63aUxY"
            srcdoc="<style>*{padding:0;margin:0;overflow:hidden}html,body{height:100%}img,span{position:absolute;width:100%;top:0;bottom:0;margin:auto}span{height:1.5em;text-align:center;font:48px/1.5 sans-serif;color:white;text-shadow:0 0 0.5em black}</style><a href=https://www.youtube.com/embed/iZL1S63aUxY?autoplay=1><img src=https://mimi.ro/videobk1.jpg alt='Mimi.ro – Welcome to the Home of Mirabilism Art'><span>▶</span></a>"
            frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
            title="Mimi.ro – Welcome to the Home of Mirabilism Art"
          ></iframe>
        </div>
        <div id="introText">
          <p id="introParagraph">
            Mimi’s art is instinctive. Her paintings tell stories inspired by every day experiences and moments, expressed through the language of her inner world. Their symbolism comes from a place within her and they are influenced by Eastern European mysteries, fairy tales and patterns. In telling her stories, she’s always searching for sincerity and for that raw emotion and joy we are all born with but tend to lose as we become adults.
            <span
              v-if="!isReadMore"
              class="readMoreTxt"
              @click="isReadMore = !isReadMore">
              READ MORE
            </span>
          </p>
          <transition name="fade">
            <div v-if="isReadMore">
              <p>
                A mirabilis artist, Mimi’s art is positive and radiates only good energy. And if you look at each of the stories she tells on canvas, you’ll always find a secret, and perhaps that secret is the key to rediscovering something genuine and true about yourself.
              </p>
              <p>
                Mimi Revencu’s first love for art started through her father’s drawings. She painted and made drawings herself throughout her youth, but it wasn’t until much later that she decided to devote her life entirely to art.
              </p>
              <p>
                She was guided in her formative years by the Romanian Master Petru Petrescu who recognized her natural talent and unique style. She developed her technique and distinctive elements with the confidence of someone who feels she has a calling, not just a plan. She uses her instincts and experiences to develop sophisticated narratives that express authentic joy and at a closer look, hide symbols and secrets.
              </p>
              <p>
               Mimi Revencu works mainly in acryl and with mixed media. Her art has been seen in numerous exhibitions throughout Europe and are part of private collections all around the world.
                <span
                v-if="isReadMore"
                class="readMoreTxt"
                @click="isReadMore = !isReadMore">
                SHOW LESS
                </span>
              </p>
            </div>
          </transition>
        </div>
      </div>
    </div>
    <div :class="[currentPage === 0 ? 'firstPageGallery' : 'galleryOuterContainer']" id="outerContainerGallery">
      <ul class="innerContainer gallery" id="mainGallery" ref="gallery">
        <router-link
          tag="li"
          v-for="painting in localPaintings"
          :key="painting.id"
          :style="{ backgroundImage: 'url(' + painting.imageThumb + '.jpg)' }"
          class="painting"
          :to="{
            name: 'painting',
            path: `/${painting.id}`,
            params:
              { 'clickedPainting': painting,
                'id': painting.id
               }
            }"
          loading="lazy"
        >
            <div class="overlay">
              <div class="overlay-title">{{ painting.name }}</div>
              <div class="overlay-seemore">
                <span
                  v-if="painting.isSold"
                  style="margin-right: 5px;">
                  Sold /
                </span>
                <span>Read more</span>
              </div>
            </div>
        </router-link>
        <!-- correct flexbox last item in space-between -->
        <li v-if="localPaintings.length % 3 !== 0" class="paintingFlexFix"></li>
      </ul>
      <div class="pagination">
        <ul>
          <li v-for="(item, index) in noOfPages" :key="index">
            <buttonCustom @click.native="setCurrentPage(index)" :button="currentPage + 1 === item ? 'neutral' : 'white'"  :text="item.toString()"/>
          </li>
        </ul>
      </div>
    </div>
    <transition name="custom-fade" mode="out-in">
      <router-view></router-view>
    </transition>
  </div>
</template>

<script>

export default {
  data () {
    return {
      modalPainting: null,
      itemsPerPage: 12,
      currentPage: 0,
      isReadMore: false
    }
  },
  computed: {
    noOfPages () {
      return Math.ceil(this.paintings.length / this.itemsPerPage)
    },
    localPaintings () {
      let startIndex = this.currentPage * this.itemsPerPage
      let endIndex = startIndex + this.itemsPerPage
      return this.paintings.slice(startIndex, endIndex)
    }
  },
  methods: {
    setCurrentPage (index) {
      this.currentPage = index
    }
  }
}
</script>

<style>
.custom-fade-enter-active {
  animation: custom-fade-in .4s;
}
.custom-fade-leave-active {
  animation: custom-fade-in .4s reverse;
}
@keyframes custom-fade-in {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity:  1;
  }
}

</style>
<template>
  <section class="outerContainer" id="outerContainerManifesto">
     <div class="innerContainer" id="innerContainerManifesto">
      <h1 class="center-h">
        Mirabilism Manifesto
      </h1>
      <br />
      <ul>
        <li v-for="manifesto in manifestos" :key="manifesto.language" class="zebra-classic" @click.prevent="setManifesto(manifesto)">
          <div>
            <span>{{ manifesto.language }} : </span>
            <span>{{ manifesto.title }}</span>
          </div>
          <div class="manifestoContainer">
            <object
              v-if="currentManifesto && currentLanguage === manifesto.language"
              type="application/pdf"
              width="90%" height="600"
              :data="currentManifesto"
            ></object>
          </div>
        </li>
      </ul>
  </div>
  </section>
</template>

<script>
export default {
  data () {
    return {
      currentManifesto: null,
      currentLanguage: null,
      manifestos: [
        {
          link: 'En-The-Mirabilism-Manifesto.pdf',
          language: 'English',
          title: 'The Mirabilism Manifesto'
        },
        {
          link: 'NL-Het-mirabilisme-manifest.pdf',
          language: 'Dutch',
          title: 'Het mirabilisme manifest'
        },
        {
          link: 'Sp-El-Manifiesto-Mirabilista.pdf',
          language: 'Spanish',
          title: 'El Manifiesto Mirabilista'
        },
        {
          link: 'FR-Le-Manifest-du-Mirabilism.pdf',
          language: 'French',
          title: 'Le Manifest du Mirabilism'
        },
        {
          link: 'CT-mirabilism-traditional-chinese.pdf',
          language: 'Traditional Chinese',
          title: '美黎比利宣言 '
        },
        {
          link: 'IT-Il-Manifesto-del-Mirabilismo.pdf',
          language: 'Italian',
          title: 'Il Manifesto del Mirabilismo'
        },
        {
          link: 'CS-mirabilism-simplified-chinese.pdf',
          language: 'Simplified Chinese',
          title: '美黎比利宣言'
        },
        {
          link: 'Ru-the-Mirabilism-Manifesto.pdf',
          language: 'Russian',
          title: 'Манифест Мирабилизма'
        },
        {
          link: 'Ro-Manifestul-Mirabilism.pdf',
          language: 'Romanian',
          title: 'Manifestul Mirabilismului'
        }
      ]
    }
  },
  methods: {
    setManifesto(manifesto) {
      this.currentManifesto = `/manifestos/${manifesto.link}`
      this.currentLanguage = manifesto.language
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/styles/variables.scss';

#outerContainerManifesto {
  background-color: $colorMimiOuterContent;
  display: block;
  height: auto;
  padding: 50px;
}

.manifestoContainer {
  margin-top: 20px;
}

#innerContainerManifesto {
  padding-top: 20px;
  background-color: $colorMimiInnerContent;
  color: $colorMimiText;
  border-radius: 10px;
  @include shadowNeu;
  font-weight: 100;
  h1 {
    font-size: 2em;
  }
  & ul li {
    padding: 10px;
      // text-decoration: none;
      cursor: pointer;
      color: $colorText;
      span {
        font-size: 16px;
        font-family: $fontDecorative;
        color: $colorText;
        font-weight: 100;
      }
    &:last-of-type {
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
    }
  }
}
</style>
<template>
  <section class="form">
      <h1 class="center-h">
        Order Paintings
      </h1>
      <br />
      <h3>
          Click "Apply Changes" when you finish, or the order will NOT be saved!
<!--     <button @click="saveOrderToConsole">Save Order {{ localPaintings.length }} </button> -->
      </h3>
      <br />
      <table v-if="localPaintings && localPaintings.length" id="orderTable">
        <tr class="tbHeadingRow zebra">
          <th class="tbHeadingCell">Name</th>
          <th class="tbHeadingCell previewCell">Preview</th>
          <th class="tbHeadingCell">Position</th>
          <th class="tbHeadingCell">Move down</th>
          <th class="tbHeadingCell">Move up</th>
        </tr>
        <tr v-for="localPainting in localPaintings" :key="localPainting.id" class="zebra">
          <td style="padding-left: 20px;">
            <span style="font-weight: bold">{{ localPainting.name }}</span>
            <br />
            <span v-if="!localPainting.isSold" style="color: brown;">Not sold</span>
            <br />
            <span v-if="!localPainting.isPaintingShown" style="color: red;">Not shown</span>
          </td>
          <td class="previewCell">
            <img :src="localPainting.imageThumb + '.jpg'" class="preview">
          </td>
          <td>
            <!-- as to NOT change all items in paintings whenever a new painting is added, new items will be pushed to the end; sorting the gallery will actually be descending. this display is to mimick what the viewer sees, not the actual position !-->
            {{ localPainting.position }}
          </td>
          <td>
            <buttonCustom @click.native="moveDown(localPainting.position, localPainting)"
            v-if="localPainting.position > 1"
            :button="'add'"
            :icon="'downarrow'"
            :text="''"/>
          </td>
          <td>
            <buttonCustom @click.native="moveUp(localPainting.position, localPainting)"
            v-if="localPaintings.length !== localPainting.position"
            :button="'cancel'"
            :icon="'uparrow'"
            :text="''"/>
          </td>
        </tr>
      </table>
      <div style="margin-top: 30px; margin-bottom: 30px;">
        <buttonCustom @click.native="reset"
          :button="'cancel'"
          :icon="'cancel'"
          :text="'Reset Changes'"/>
        <buttonCustom @click.native="save"
          :button="'save'"
          :icon="'save'"
          :text="'Apply Changes'"/>
      </div>
      <div v-if="isUpdated">
        <h1 style="color: green;">Changes applied successfully!</h1>
      </div>
  </section>
</template>

<script>
import { db } from '@/firebaseInit'
import { actions } from '@/store'


export default {
  data () {
    return {
      isUpdated: false,
      localPaintings: [],
    }
  },
  mounted () {
    this.localPaintings = this.copyOriginalPaintings()
  },
  methods: {
    sortPaintings () {
      this.localPaintings.sort((a, b) => b.position - a.position)
    },
    copyOriginalPaintings () {
      let result = []
      for (let painting of this.userPaintings) {
        let copy = {
          imageThumb: painting.imageThumb,
          id: painting.id,
          name: painting.name,
          position: painting.position,
          isSold: painting.isSold,
          isPaintingShown: painting.isPaintingShown
        }
        result.push(copy)
      }
      return result
    },
    moveUp(currentPosition, painting) {
      let prevEl = this.localPaintings.find(el => el.position === currentPosition + 1)
      prevEl.position -= 1
      painting.position += 1
      this.sortPaintings()
    },
    moveDown (currentPosition, painting) {
      let prevEl = this.localPaintings.find(el => el.position === currentPosition - 1)
      prevEl.position += 1
      painting.position -= 1
      this.sortPaintings()
    },
    reset () {
      this.localPaintings = this.copyOriginalPaintings()
    },
    save () {
      const promises = []
      for (let local of this.localPaintings) {
        let original = this.userPaintings.find(el => el.id === local.id)
        if (original.position !== local.position) {
          let promise = new Promise((resolve, reject) => {
            return db.collection('paintings').doc(local.id).update({
              position: local.position
            })
            .then(() => resolve())
            .catch(() => reject())
          })
          promises.push(promise)
        }
      }
      if (promises.length) {
        Promise.all(promises)
        .then(() => {
          actions.initializePaintings()
          this.isUpdated = true
          setTimeout(() => {
            this.isUpdated = false
          }, 5000)
        })
        .catch(err => console.log(err))
      }
    },
    saveOrderToConsole () {
      let order = []
      let all = [...this.localPaintings]
      for (let i = 0; i < all.length; i++) {
        order.push({
          name: all[i].name,
          position: all[i].position,
          newPosition: all.length - i
        })
      }
      for (let i = 0; i < order.length; i++) {
        if (order[i].position !== order[i].newPosition) {
          console.log(order[i])
        }
      }
    }
  }
}
</script>

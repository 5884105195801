<template>
  <button v-if="button === 'close'" class="close-button" @click="$emit('close')" aria-label="Close modal">
    <span>X</span>
  </button>
  <button v-else :class="buttonClass">
    <span :class="iconClass"> </span>
    <span v-if="text">{{ text }}</span>
  </button>
</template>

<script>
export default {
  props: {
    button: {
      type: String,
      required: true
    },
    icon: {
      type: String
    },
    text: {
      type: String
    }
  },
  computed: {
    buttonClass () {
      let computedClass = this.button + '-button'
      if (this.icon) computedClass += ' button-w-icon'
      return computedClass
    },
    iconClass () {
      const computedIconClass = 'icon ' + this.icon + '-icon'
      return computedIconClass
    }
  }
}
</script>

<template>
  <div class="captcha">
    <span>What is </span>
    <span> {{ randomNo1 }} + </span>
    <span> {{ randomNo2 }} ?</span>
  </div>
</template>

<script>
export default {
  data () {
    return {
      minNo: 1,
      maxNo: 8,
      randomNo1: 0,
      randomNo2: 0,
      total: 0
    }
  },
  created () {
    this.randomNo1 = this.getRandomNum()
    this.randomNo2 = this.getRandomNum()
    this.total = this.randomNo2 + this.randomNo1
    this.$emit('totalCreated', this.total)
  },
  methods: {
    getRandomNum () {
      return Math.floor(Math.random() * (this.maxNo - this.minNo) + this.minNo)
    }
  }
}
</script>

<style scoped lang="scss">
.captcha {
  white-space: pre;
}
</style>
import Vue from 'vue'
import VueRouter from 'vue-router'
import PageNotFound from '../views/PageNotFound.vue'
import Login from '../views/Login.vue'
import Signup from '../views/SignUp.vue'
import PersonalGalleryAdmin from '../views/PersonalGalleryAdmin.vue'
import UserAdmin from '../views/UserAdmin.vue'
import MainGallery from '../views/MainGallery.vue'
import Profile from '../views/Profile.vue'
import Messages from '../views/Messages.vue'
import Manifesto from '../views/Manifesto.vue'
import Contact from '../views/Contact.vue'
import FormContacts from '../views/FormContacts'
import SortWorks from '../views/SortWorks'
import { store } from '@/store'

Vue.use(VueRouter)

const routes =
[
  {
    path: '*',
    name: '404',
    component: PageNotFound
  },
  {
    path: '/manifesto',
    name: 'manifesto',
    component: Manifesto
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/signup',
    name: 'signup',
    component: Signup
  },
  {
    path: '/profile',
    name: 'profile',
    component: Profile
  },
  {
    path: '/messages',
    name: 'messages',
    component: Messages
  },
  {
    path: '/contact',
    name: 'contact',
    component: Contact
  },
  {
    path: '/sortworks',
    name: 'sortworks',
    component: SortWorks
  },
  {
    path: '/formcontacts',
    name: 'formcontacts',
    component: FormContacts,
    meta: {
      requiresAdmin: true
    }
  },
  {
    path: '/mypaintings',
    name: 'PersonalGalleryAdmin',
    component: PersonalGalleryAdmin,
    meta: {
      requiresLogin: true
    }
  },
  {
    path: '/admin',
    name: 'UserAdmin',
    component: UserAdmin,
    meta: {
      requiresAdmin: true
    }
  },
  {
    path: '/',
    name: 'MainGallery',
    component: MainGallery,
    children: [
      {
        path: '/:id',
        component: () => import("../components/PaintingModal.vue"),
        name: 'painting',
        props: true
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {
  const requiresAdmin = to.matched.some(record => record.meta.requiresAdmin)
  const requiresLogin = to.matched.some(record => record.meta.requiresLogin)
  const isAdmin = store.user.isAdmin
  if (requiresAdmin && !isAdmin) {
    console.log('not allowed')
    next('/')
  } else if (requiresLogin && !store.user.uid) {
    console.log('must be logged in')
    next('/')
  } else {
    next()
  }
})

export default router

<template>
  <section class="outerContainer" id="outerContainerContact">
    <div class="innerContainer" id="innerContainerContact" v-if="!isSent">
      <form @submit.prevent="send()">
        <h1 class="center-h">Contact Me</h1>
        <div class="contact-row">
          <label for="clientName">Name: </label>
          <input type="text" name="clientName" id="clientName" v-model="client.name">
          <transition name="fade">
            <div class="error" v-if="errors.name">{{ errors.name }}</div>
          </transition>
        </div>
        <div class="contact-row">
          <label for="clientPhone">Phone: </label>
          <input type="text" name="clientPhone" id="clientPhone" v-model="client.phone">
        </div>
        <div class="contact-row">
          <label for="clientEmail">Email: </label>
          <input type="text" name="clientEmail" id="clientEmail" v-model="client.email">
          <transition name="fade">
            <div class="error" v-if="errors.email">{{ errors.email }}</div>
          </transition>
        </div>
        <div class="contact-row">
          <label for="occasion">Occasion: </label>
          <select v-model="client.occasion" id="occasion" name="occasion">
              <option v-for="(occasion, index) in occasions" :key="index">
                {{ occasions[index] }}
              </option>
          </select>
        </div>
        <div class="contact-row">
          <label for="clientMessage">Message: </label>
          <textarea name="clientMessage" id="clientMessage" v-model="client.message" />
          <transition name="fade">
            <div class="error" v-if="errors.message">{{ errors.message }}</div>
          </transition>
        </div>
        <div class="contact-row">
          <captcha class="contact-rowInput" @totalCreated="getAntiSpamTotal"/>
          <input type="number" v-model.number="controlInputNumber" name="controlInputNumber" id="controlInputNumber">
          <transition name="fade">
            <div class="error" v-if="errors.antispam">{{ errors.antispam }}</div>
          </transition>
        </div>
        <div class="button-row">
          <buttonCustom type="reset" :button="'neutral'" :icon="'cancel'" :text="'Reset'" @click.native="reset" />
          <buttonCustom type="submit" :button="'save'" :icon="'confirm'" :text="'Send'"/>
        </div>
      </form>
    </div>
    <transition name="fade" v-else>
      <div class="modalDescription buyForm alignCenter">
        <h1>Thank you!</h1>
        <br />
        <div>Your message has been sent!</div>
      </div>
    </transition>
  </section>
</template>


<script>
import Captcha from '@/components/Captcha'
import { db } from '@/firebaseInit'
import axios from 'axios'

export default {
  components: {
    'captcha': Captcha
  },
  name: 'contactForm',
  data () {
    return {
      controlInputNumber: null,
      antiSpamNumber: null,
      errors: this.getFreshErrors(),
      client: this.getNewClient(),
      isSent: false,
      occasions: ['Please select', 'Searching vibrating art', 'Buy a gift', 'Asking for a quotation', 'Just because', 'Expressing my feelings']
    }
  },
  mounted () {
    this.reset()
  },
  methods: {
    getAntiSpamTotal (val) {
      this.antiSpamNumber = val
    },
    getFreshErrors () {
      return {
        email: null,
        antispam: null,
        name: null,
        message: null,
        occasion: null
      }
    },
    getNewClient () {
      return {
        name: null,
        email: null,
        phone: null,
        message: null,
        occasion: 'Please select'
      }
    },
    reset () {
      this.client = this.getNewClient()
      this.errors = this.getFreshErrors()
    },
    send () {
      this.errors = this.getFreshErrors()
      if (!this.client.name) {
        this.errors.name = 'Name required'
      }
      if (!this.client.email) {
        this.errors.email = 'Email required'
      }
      if (!this.client.message) {
        this.errors.message = 'The message cannot be empty!'
      }
      if (this.controlInputNumber !== this.antiSpamNumber) {
        this.errors.antispam = 'Not the right answer. Please try again'
      }
      if (!this.controlInputNumber) {
        this.errors.antispam = 'Please write the sum of the numbers above'
      }
      if (this.client.email && !this.validEmail(this.client.email)) {
        this.errors.email = 'Valid email please'
      }

      const formHasNoErrors = !Object.values(this.errors).some(x => (x !== null && x !== ''))

      if (formHasNoErrors) {
        const toSend = {
          ...this.client
        }

        let date = new Date()
        toSend.date = date
        toSend.formattedDate = date.toLocaleDateString('ro-RO') + ' ' + date.toLocaleTimeString('ro-RO');
        toSend.isRead = false
        if (!toSend.phone) {
          toSend.phone = 'Not filled in'
        }
        if (toSend.occasion === 'Please select') {
          toSend.occasion = 'Not selected'
        }

        axios.post('./contactform.php', toSend )
        .then(() => {
            db.collection('contacts')
              .add(toSend)
              .catch(err => console.log(err))
        })
        .catch(err => console.log(err))
        .finally(() => {
          this.isSent = true
         })

      } else {
        return false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';

#outerContainerContact {
  background-color: $colorMimiOuterContent;
  display: block;
  height: auto;
  padding: 50px;
}

#innerContainerContact {
  font-family: $fontDecorative;
  width: 80vw;
  max-width: 1250px;
  margin: 0 auto;
  padding: 20px 0;
  background-color: $colorMimiInnerContent;
  color: $colorMimiText;
  border-radius: 10px;
  @include shadowNeu;
  font-weight: 100;
  h1 {
    font-size: 2em;
  }
  .contact-row {
    padding: 10px 40px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    @media only screen and (min-width: 700px) {
      flex-direction: row;
      justify-content: center;
      flex-wrap: wrap;
    }
    .error {
      color: red;
      margin-top: 10px;
      @media only screen and (min-width: 700px) {
        width: 100%;
        flex-basis: 100%;
        margin-left: 140px;
      }
    }
    label, input, .error, .captcha, select, textarea {
      flex: 1 0 auto;
      display: flex;
      flex-direction: row;
      justify-content: center;
      @media only screen and (max-width: 700px) {
        width: 100%;
      }
    }
    label, .captcha {
      text-align: center;
      font-size: 16px;
      @media only screen and (min-width: 700px) {
        justify-content: flex-end;
        margin-right: 20px;
        margin-top: 15px;
        flex: 0 0 120px;
      }
      span {
        font-size:16px;
      }
    }
    input, select, textarea, option{
      font-size: 16px;
      font-family: $fontDecorative;
      background-color: #eee;
      text-align: center;
      margin-top: 10px;
      border: 1px solid $colorText;
    }
  }
}


.button-w-icon {
  margin: 10px;
  width: 180px;
  @media only screen and (max-width: 800px) {
    width: 140px;
    height: 70px;
  }
}


select {
    text-align: center;
    text-align-last: center;
    -moz-text-align-last: center;
}

.alignCenter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: green;
  min-height: 200px;
}

</style>

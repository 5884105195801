<template>
  <div class="modalBackground">
    <div class="modalContent" id="personalModalContent">
      <form @submit.prevent="onSubmit">
        <h1>Change your {{ toChange }}</h1>
        <div class="form-row">
          <label for="inputToChange">Enter New:</label>
          <input v-if="toChange === 'password'" type="password" name="inputToChange" id="inputToChange" class="form-rowInput" autocomplete="off" v-model="form.inputValue"/>
          <input v-else type="text" name="inputToChange" id="inputToChange" class="form-rowInput" autocomplete="off" v-model="form.inputValue"/>
        </div>
        <div class="form-row" v-if="requiresPassword">
          <label for="password">Current password:</label>
          <input type="password" name="password" id="password" class="form-rowInput" autocomplete="off" v-model="form.inputPassword"/>
        </div>
        <transition v-if="error" name="fade">
          <div class="error">{{ error }}</div>
        </transition>
        <div class="form-row error" v-if="requiresPassword">
          You have to login again after this change!
        </div>
        <div class="form-row">
          <buttonCustom @click.native="onCancel" :button="'cancel'" :icon="'cancel'" :text="'Cancel'"  />
          <buttonCustom type="submit" :button="'save'" :icon="'save'" :text="'Save'"  />
        </div>
        <buttonCustom @click.native="onCancel" :button="'close'"  />
      </form>
    </div>
  </div>
</template>

<script>
import { db, auth, fb } from '@/firebaseInit'
import { actions } from '@/store'

export default {
  props: {
    toChange: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      form: {
        inputValue: '',
        inputPassword: ''
      },
      error: null
    }
  },
  mounted () {
    this.getFreshData()
  },
  computed: {
    initialValue () {
      let val = this.tocamelCase(this.toChange)
      return this.toChange === 'password' ? '' : this[val]
    },
    userUpdated () {
      let obj = {}
      obj[this.tocamelCase(this.toChange)] = this.form.inputValue
      return obj
    },
    requiresPassword () {
      return this.toChange === 'email' || this.toChange === 'password'
    },
    collection () {
      return this.userIsAdmin ? db.collection('admins').doc(this.userUid) : db.collection('users').doc(this.userUid)
    }
  },
  methods: {
    tocamelCase(str) {
      if (!str.includes(' ')) {
        return str
      } else {
        let result = ''
        let arr = str.split(' ')
        for (let i = 0; i < arr.length; i++) {
          if (i === 0) {
            result += arr[i]
          } else {
            result += arr[i][0].toUpperCase() + arr[i].slice(1)
          }
        }
        return result
      }
    },
    getFreshData () {
      this.form.inputValue = this.initialValue
    },
    onCancel () {
      this.$emit('close')
    },
    onSubmit () {
      this.error = null
      if (!this.form.inputValue) {
        this.error = 'Please fill in the new value'
      } else if (this.toChange === 'phone number' && this.form.inputValue.length < 6) {
        this.error = 'Phone number is too short'
      } else if (this.toChange === 'email' && !this.validEmail(this.form.inputValue)) {
        this.error = 'Valid email required'
      } else if (this.requiresPassword && !this.form.inputPassword) {
        this.error = 'Please fill in the current password'
      }
      if (this.error) {
        return false
      }

      let p1
      switch (this.toChange) {
        case 'phone number': {
          p1 = new Promise((resolve, reject) => {
            this.collection.update({
              phoneNumber: this.form.inputValue
            })
            .catch(err => reject(err.message))
            .then(() => resolve())
          })
        }
        break
        case 'display name':
          if (this.userIsAdmin) {
            p1 = new Promise((resolve) => {
              auth.currentUser.updateProfile({
                displayName: this.form.inputValue
              })
              resolve()
            })
          } else {
            p1 = new Promise((resolve) => {
              db.collection('users').doc(this.userUid).update({
                displayName: this.form.inputValue
              })
              resolve()
            })
          }
        break
        case 'password':
          fb.auth()
            .signInWithEmailAndPassword(this.email, this.form.inputPassword)
            .catch((err) => {
              this.error = err.message
            })
            .then(() => {
              auth.currentUser.updatePassword(this.form.inputValue)
            })
            .catch(err => {
              this.error = err.message
            })
            .then(() => {
              this.logOut()
            })
            .catch(err => {
              this.error = err.message
            })
            return false

        case 'email':
          p1 = new Promise(resolve => {
            this.collection.update({
              email: this.form.inputValue
            })
            resolve()
          })

          return p1.then(() => {
            this.updatePaintings()
          })
          .then(() => {
            return fb.auth()
              .signInWithEmailAndPassword(this.email, this.form.inputPassword)
              .then(userCredential => {
                return userCredential.user.updateEmail(this.form.inputValue)
              })
            })
          .then(() =>
            auth.signOut().catch(function (error) {
              let errorCode = error.code
              let errorMessage = error.message
              if (errorMessage) console.log(`Error code: ${errorCode} with message: ${errorMessage}`)
            })
            .then(() => {
              if (this.$route.path !== '/') {
                this.$router.replace('/')
              }
            })
            .then(() => {
              return false
            })
          )
        }

      // generic sequence for phone number && display name
      p1
      .then(() => {
        Promise.all([
          actions.updateUser(this.userUpdated),
          this.updatePaintings()
        ])
      })
      .then(() => {
        this.onCancel()
      })
      .catch(err => {
        this.error = err.message
      })
    },
    logOut () {
      return new Promise((resolve) => {
        auth.signOut().catch(function (error) {
          let errorCode = error.code
          let errorMessage = error.message
          if (errorMessage) console.log(`Error code: ${errorCode} with message: ${errorMessage}`)
        })
        .then(() => {
          if (this.$route.path !== '/') {
            this.$router.replace('/')
          }
        })
        .then(() => {
          resolve()
        })
      })
    },
    updatePaintings () {
      const paintingsDb = db.collection('paintings')

      paintingsDb.where('userUid', '==', this.userUid).get().then(response => {
        const batch = db.batch()
        response.docs.forEach((doc) => {
          const docRef = paintingsDb.doc(doc.id)
          batch.update(docRef, this.userUpdated)
        })
        batch.commit().then(() => {
          console.log(`updated all documents inside ${paintingsDb}`)
        })
      })
      .then(() => {
        actions.initializePaintings()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';

#personalModalContent {
  position: relative;
  width: 90%;
  max-width: 700px;
  height: auto;
  padding: 30px 0;
  margin: 0;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  & form {
    height: auto;
    margin: 0 auto;
    box-shadow: none;
    background-color: white;
    .form-row {
      display: flex;
      justify-content: space-around;
      align-items: center;
      label {
        min-width: 200px;
      }
    }
  }
}

.error {
  color: red;
}
</style>


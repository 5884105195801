import Vue from 'vue'
import { db, fb } from '@/firebaseInit'

const emptyUser = {
  uid: null,
  email: null,
  phoneNumber: null,
  isAdmin: false,
  displayName: null,
  messages: []
}

export const store = Vue.observable({
  paintings: [],
  allPaintings: [],
  user: { ...emptyUser },
  contacts: []
})

export const mutations = {
  getMessages () {
    store.user.messages = []
    let dbRef = store.user.isAdmin ? db.collection('admins') : db.collection('users')
    let docRef = dbRef.doc(store.user.uid).collection('messages')
    docRef
    .orderBy('date', 'desc')
    .get().then((querySnapshot) => {
        if (querySnapshot) {
        querySnapshot.docs.forEach(m => {
          let message = m.data()
          message.id = m.id
          store.user.messages.push(message)
        })
      } else {
        console.log('no messages')
      }
    })
  },
  getContacts () {
    store.contacts = []
    db.collection('contacts')
    .orderBy('date', 'desc')
    .get().then((querySnapshot) => {
        if (querySnapshot) {
        querySnapshot.docs.forEach(m => {
          let message = m.data()
          message.id = m.id
          store.contacts.push(message)
        })
      } else {
        console.log('no contacts')
      }
    })
  },
  setUser (user) {
    if (user) {
      store.user.uid = user.uid
      store.user.email = user.email
      store.user.phoneNumber = user.phoneNumber
      store.user.displayName = user.displayName
      localStorage.setItem('mimi_gallery_user', user)
      /* avoid firebase phone verification */
        if (store.user.isAdmin) {
          var docRef = db.collection("admins").doc(store.user.uid)
          docRef.get().then((doc) => {
              if (doc.exists) {
                  let data = doc.data()
                  if (data.phoneNumber) {
                    store.user.phoneNumber = data.phoneNumber
                  }
                  this.getMessages()
                  this.getContacts()
              } else {
                  console.log("No such document!");
              }
          }).catch((error) => {
              console.log("Error getting document:", error);
          })
        }
        else {
          // user not admin, set messages
          let docRef = db.collection('users').doc(store.user.uid)
          docRef.get().then((doc) => {
              if (doc.exists) {
                this.getMessages()
              }
          }).catch((error) => {
              console.log("Error getting document:", error);
          })
        }
    } else {
      store.user = { ...emptyUser }
      localStorage.removeItem('mimi_gallery_user')
    }
  },
  updateUser (details) {
    console.log('in mutations', details)
    let key = Object.keys(details)[0]
    let value = details[key]
    store.user[key] = value
  },
  setIsAdmin (isTruthy) {
    if (isTruthy) {
      store.user.isAdmin = isTruthy
      localStorage.setItem('mimi_gallery_isAdmin', isTruthy)
    } else {
      store.user.isAdmin = false
      localStorage.removeItem('mimi_gallery_isAdmin')
    }
  }
}

export const actions = {
  initializePaintings () {
    store.paintings = []
    store.allPaintings = []
    db.collection('paintings')
      .orderBy('position', 'desc')
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach(doc => {
          const data = doc.data()
          data.id = doc.id // transform doc id into id prop for display
          if (data.isPaintingShown) {
            store.paintings.push(data)
          }
          store.allPaintings.push(data)
        })
      })
      .catch(err => console.log(err))
      .then(() => {
        console.log('initialized')
      })
      .catch(error => console.log('Error getting document:', error))
  },
  authCheckUser () {
    fb.auth().onAuthStateChanged(user => {
      if (user) {
        actions.checkIfAdmin(user)
          .then(val => {
            if (val) {
            // admin
              mutations.setIsAdmin(true)
              mutations.setUser(user)
            } else {
            // regular user
              db.collection('users').doc(user.uid).get().then(doc => {
                if (doc.exists) {
                  const userData = doc.data()
                  userData.uid = doc.id
                  mutations.setUser(userData)
                } else {
                // not a regular user, must be admin
                  console.log('user is not in the users db')
                }
              })
                .catch(err => console.log(err))
            }
          })
      } else {
        // this must be logged out
        mutations.setIsAdmin(false)
        mutations.setUser(null)
      }
    })
  },
  checkIfAdmin (user) {
    return new Promise(resolve => {
      db.collection('admins')
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach(doc => {
            if (doc.id === user.uid) {
              resolve(true)
            }
          })
          resolve(false)
        })
        .catch(error => console.log('Error getting document:', error))
    })
  },
  updateUser (details) {
    mutations.updateUser(details)
  },
  getMessages () {
    mutations.getMessages()
  },
  getContacts () {
    mutations.getContacts()
  }
}

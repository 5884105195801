<template>
  <div class="modalBackground">
    <div id="editModalContent">
      <div v-if="!uploaded">
        <h1 v-if="isNew" class="center-header">Add New Painting</h1>
        <h1 v-else class="center-header">Editing "{{ painting.name }}"</h1>
      </div>
      <transition v-if="uploaded" name="fade">
        <div class="verticalcenter">
          <div class="success" v-if="isNew">Painting has been added!</div>
          <div class="success" v-else>Painting has been updated!</div>
        </div>
      </transition>
      <form v-if="!uploaded" @submit.prevent="onSubmitPainting" class="paintingForm">
        <div class="form-third displayedThumb" :style="{'background-image': `url(${displayedThumb})`}"></div>
        <div class="form-two-thirds">
          <div class="form-row zebra" v-if="isNew">
            <label for="imageFile">Upload image</label>
            <input type="file" name="imageFile" id="imageFile" accept="image/*" @change="onImageFileChange" ref="inputFile"/>
            <transition name="fade">
              <div class="error" v-if="errors.imageFile">{{ errors.imageFile }}</div>
            </transition>
          </div>
          <div class="form-row zebra" v-if="isNew">
            <label for="name">Name:</label>
            <input type="text" name="name" id="name" v-model="painting.name" placeholder="painting name" @change="onNameChange" class="wide-input"/>
            <transition name="fade">
              <div class="error" v-if="errors.name">{{ errors.name }}</div>
            </transition>
          </div>
          <div class="form-row zebra">
            <label for="name">Description:</label>
            <textarea name="description" id="description" v-model="painting.description" placeholder="painting description" class="wide-input"/>
          </div>
          <div class="form-row zebra">
            <label for="technique">Technique</label>
            <select name="technique" id="technique" v-model="painting.technique">
              <option v-for="option in options.techniques" :key="option" v-bind:value="option">
                {{ option }}
              </option>
            </select>
          </div>
          <div class="form-row-inline zebra">
            <div class="inline-option">
              <label for="year">Year</label>
              <input type="number" name="year" id="year" v-model="painting.year" placeholder="YEAR" maxlength="4" size="5">
              <transition name="fade">
                <div class="error" v-if="errors.year">{{ errors.year }}</div>
              </transition>
            </div>
            <div class="inline-option">
              <label for="isFramed">Framed:</label>
              <input type="checkbox" name="isFramed" id="isFramed" v-model="painting.isFramed">
            </div>
          </div>
          <div class="form-row-inline zebra">
            <div class="inline-option">
              <label for="width">Width</label>
              <input type="number" name="width" id="width" v-model="painting.size.width" maxlength="5" size="5">
              <transition name="fade">
                <div class="error" v-if="errors.width">{{ errors.width }}</div>
              </transition>
            </div>
            <div class="inline-option">
              <label for="length">Height</label>
              <input type="number" name="length" id="height" v-model="painting.size.height" maxlength="5" size="5">
              <transition name="fade">
                <div class="error" v-if="errors.height">{{ errors.height }}</div>
              </transition>
            </div>
            <div class="inline-option">
              <label for="unit">Unit</label>
              <select name="unit" id="unit" v-model="painting.size.unit">
                <option v-for="option in options.units" :key="option" v-bind:value="option">
                  {{ option }}
                </option>
              </select>
            </div>
          </div>
          <div class="form-row-inline zebra">
            <div class="inline-option">
              <label for="price">Price</label>
              <input type="number" name="price" id="price" min="0" v-model="painting.price.value" maxlength="6" size="6"/>
            </div>
            <div class="inline-option">
              <label for="currency">Currency</label>
              <select name="currency" id="currency" v-model="painting.price.currency">
                <option v-for="option in options.currencies" :key="option" v-bind:value="option">
                  {{ option }}
                </option>
              </select>
            </div>
          </div>
          <div class="form-row-inline zebra">
            <div class="inline-option">
              <label for="isPaintingShown">Show painting:</label>
              <input type="checkbox" name="isPaintingShown" id="isPaintingShown" v-model="painting.isPaintingShown"/>
            </div>
            <div class="inline-option">
              <label for="isSold">Is sold?</label>
              <input type="checkbox" name="isSold" id="isSold" v-model="painting.isSold"/>
            </div>
            <div class="inline-option">
              <label for="isPriceShown">Show Price?</label>
              <input type="checkbox" name="isPriceShown" id="isPriceShown" v-model="painting.price.isPriceShown"/>
            </div>
          </div>
        </div>
        <div class="form-buttons">
          <buttonCustom type="reset" @click.native="onCancel" :button="'cancel'" :icon="'cancel'" :text="'Cancel'"  />
          <buttonCustom type="submit" :button="'add'" :icon="'save'" :text="'Save Painting'"  />
        </div>
        <buttonCustom @click.native="onCancel" :button="'close'" />
      </form>
    </div>
  </div>
</template>

<script>
import firebase from 'firebase/app'
import 'firebase/storage'
import { actions } from '@/store'
import { db } from '@/firebaseInit'

export default {
  props: {
    paintingToEdit: Object
  },
  data () {
    return {
      errors: this.getNewErrors(),
      options: {
        currencies: ['euro', 'lei', 'usd'],
        techniques: ['Acrylic on canvas', 'Oil on canvas', 'Others'],
        units: ['cm', 'inch']
      },
      uploaded: false,
      displayedThumb: null
    }
  },
  computed: {
    painting () {
      if (this.paintingToEdit) {
        return JSON.parse(JSON.stringify(this.paintingToEdit))
      } else {
        return this.getNewPainting()
      }
    },
    isNew () {
      return !this.paintingToEdit
    }
  },
  created () {
    if (this.paintingToEdit && this.painting) {
      this.displayedThumb = this.painting.imageThumb
    } else {
      this.displayedThumb = require('@/assets/placeholder.png')
    }
  },
  methods: {
    getNewPainting () {
      return {
        name: '',
        description: '',
        price: {
          value: 0,
          currency: 'lei',
          isPriceShown: true
        },
        isPaintingShown: true,
        isFramed: false,
        isSold: false,
        technique: 'Oil on canvas',
        imageFile: null,
        imageThumb: null,
        year: new Date().getFullYear(),
        size: {
          width: 0,
          height: 0,
          unit: 'cm'
        }
      }
    },
    getNewErrors () {
      return {
        name: null,
        imageFile: null,
        year: null,
        height: null,
        width: null
      }
    },
    onCancel () {
      this.errors = this.getNewErrors()
      this.$emit('closeModal')
    },
    async onSubmitPainting () {
      this.errors = this.getNewErrors()
      // validate submit painting form
      if (!this.painting.name) {
        this.errors.name = 'Must have a name'
      }
      if (!this.painting.imageFile) {
        this.errors.imageFile = 'Please upload an image'
      }
      if (!this.painting.year) {
        this.errors.year = 'Please write the year'
      }
      const formHasNoErrors = !Object.values(this.errors).some(x => (x !== null && x !== ''))
      if (!formHasNoErrors) {
        return false
      } else {
        // create identifier
        let identifier
        if (this.isNew) {
          identifier = Math.random().toString(16).slice(2, 10)
        } else {
          identifier = this.painting.imageRef
        }

        // check doc exists in db
        // TODO: needs re-write with new identifier
        const dbRef = db.collection('paintings').doc(identifier)
        const doc = await dbRef.get()

        if (doc.exists && this.isNew) {
          this.errors.name = 'You already have a painting with the same name!'
          return false
        } else if (doc.exists) {
          // existing painting update
          db.collection('paintings').doc(identifier).update({
            ...this.painting
          }).then(() => {
            this.finalizeAddOrEdit()
          })
        } else {
          // add new painting
          // 1. get storage, files reference, file: file to be uploaded, variant: for the db
          const storageRef = firebase.storage().ref()
          const files = [
            {
              ref: storageRef.child(identifier),
              file: this.painting.imageFile,
              variant: 'imageFile'
            },
            {
              ref: storageRef.child(identifier + '_thumb'),
              file: this.painting.imageThumb,
              variant: 'imageThumb'
            }
          ]
          // 2. update some props in painting
          this.painting.displayName = this.displayName
          this.painting.email = this.email
          this.painting.userUid = this.userUid
          this.painting.phoneNumber = this.phoneNumber
          this.painting.imageRef = identifier
          this.painting.position = this.allPaintings.length
          const currentDate = new Date()
          this.painting.timestamp =  currentDate.getTime()

          // 3. upload files via promises
          const promises = []
          for (const file of files) {
            promises.push(this.uploadAsPromise(file))
          }

          Promise.all(promises).then(() => {
            // 4. set values in db
            db.collection('paintings').doc(identifier).set({
              ...this.painting
            }).then(() => {
            // 5. restore view
              this.finalizeAddOrEdit()
            })
          })
        }
      }
    },
    finalizeAddOrEdit () {
      this.uploaded = true
      actions.initializePaintings()
      setTimeout(() => {
        this.uploaded = false
        this.$emit('closeModal')
      }, 2000)
    },
    uploadAsPromise (file) {
      return new Promise(resolve => {
        const uploadTask = file.ref.put(file.file)
        uploadTask.on('state_changed', snapshot => {
          // Observe state change events such as progress
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          console.log('Upload is ' + progress + '% done')
        }, error => {
          console.log('Error', error)
        }, complete => {
          console.log(111, complete)
          // get the download URL:
          uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
            this.painting[file.variant] = downloadURL
            resolve()
          })
        })
      })
    },
    onNameChange () {
      if (this.painting.name) {
        this.errors.name = null
      }
    },
    getBlobFromCanvas (mycanvas) {
      return new Promise(function (resolve) {
        mycanvas.toBlob((blob) => {
          resolve(blob)
        }, 'image/jpeg', 0.7)
      })
    },
    onImageFileChange (evt) {
      this.errors.imageFile = null

      // can change: thumb width (now 300), large width(now 800), file compression (now 0.7)
      const file = evt.target.files[0]
      const reader = new FileReader()

      reader.readAsDataURL(file)
      reader.onload = evt => {
        const img = new Image()
        img.onload = () => {
          // create resized images
          const thumbCanvas = document.createElement('canvas')
          const largeCanvas = document.createElement('canvas')
          const ctxThumb = thumbCanvas.getContext('2d')
          const ctxLarge = largeCanvas.getContext('2d')
          thumbCanvas.width = 300
          largeCanvas.width = 800
          thumbCanvas.height = thumbCanvas.width * (img.height / img.width)
          largeCanvas.height = largeCanvas.width * (img.height / img.width)
          ctxThumb.drawImage(img, 0, 0, thumbCanvas.width, thumbCanvas.height)
          ctxLarge.drawImage(img, 0, 0, largeCanvas.width, largeCanvas.height)

          // just 4 display
          this.displayedThumb = thumbCanvas.toDataURL('image/jpeg', 0.7)

          this.getBlobFromCanvas(thumbCanvas).then(res => {
            this.painting.imageThumb = res
          })

          this.getBlobFromCanvas(largeCanvas).then(res => {
            this.painting.imageFile = res
          })
        }
        img.src = evt.target.result
      }

      reader.onerror = evt => {
        console.error(evt)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';
.paintingForm {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-height: calc(100vh - 210px);
  overflow-y: auto;
  border-radius: 4px;
}

.form-two-thirds {
  flex: 1 0 60%;
}

.form-third {
  flex: 1 0 300px;
  text-align: center;
  padding: 10px 0;
  margin-right: 5px;
  margin-bottom: 5px;
}

.displayedThumb {
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  min-width: 300px;
  min-height: 200px;
}

.form-buttons {
  flex: 0 0 100%;
  margin: 10px auto;
}

.form-row {
  text-align: left;
  padding: 1em;
  display: flex;
}

.wide-input {
  flex: 1 0 auto;
}

.form-row label {
  flex: 0 0 100px;
  margin-right: 5%;
}

.form-row-inline {
  padding: 1em;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  .inline-option {
    margin-right: 1em;
    border-bottom: 10px solid transparent;
    label {
      margin-right: .5em;
    }
    input {
      max-width: 70px;
    }
  }
}

.center-header {
  font-size: 20px;
  margin: 20px;
  font-family: $fontPrimary;
}

input[type="checkbox"] {
  width: 15px;
  height: 15px;
}

.img-preview {
  width: 200px;
  height: auto;
}

.error {
  color: red;
  text-align: right;
}

.success {
  color: green;
  text-align: center;
  background-color: white;
  padding: 30px 20px 10px 20px;
}

.verticalcenter {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

</style>

<template>
  <section class="form">
      <h1 class="center-h">
        Options
      </h1>
      <br />
      <div class="form-row zebra">
        <span class="form-rowInput">Email: {{ email }}</span>
        <buttonCustom v-if="email" @click.native="toChange = 'email'" :button="'edit'" :icon="'edit'" :text="'change'"/>
        <buttonCustom v-else @click.native="toChange = 'email'" :button="'add'" :icon="'add'" :text="'add'"/>
      </div>
      <div class="form-row zebra">
        <span id="displayName" class="form-rowInput">Display Name: {{ displayName }}</span>
        <buttonCustom v-if="displayName" @click.native="toChange = 'display name'" :button="'edit'" :icon="'edit'" :text="'change'"/>
        <buttonCustom v-else @click.native="toChange = 'display name'" :button="'cancel'" :icon="'add'" :text="'add'"/>
      </div>
      <div class="form-row zebra">
        <span class="form-rowInput">Phone Number {{ phoneNumber}} </span>
        <buttonCustom v-if="phoneNumber" @click.native="toChange = 'phone number'" :button="'edit'" :icon="'edit'" :text="'change'"/>
        <buttonCustom v-else @click.native="toChange = 'phone number'" :button="'cancel'" :icon="'add'" :text="'add'"/>
      </div>
      <div class="form-row zebra">
        <span class="form-rowInput">Password: hidden</span>
        <buttonCustom @click.native="toChange = 'password'" :button="'edit'" :icon="'edit'" :text="'change'"/>
      </div>
      <option-modal v-if="toChange" :toChange="toChange" @close="toChange=null"/>
  </section>
</template>


<script>
import ProfileOptionModal from '@/components/ProfileOptionModal'

export default {
  components: {
    'option-modal': ProfileOptionModal
  },
  data () {
    return {
      form: {
        email: null,
        displayName: null,
        password: null,
        phoneNumber: null
      },
      errors: {
        email: null,
        displayName: null,
        password: null,
        phoneNumber: null
      },
      toChange: null
    }
  },
  created () {
    this.form.displayName = this.displayName
    this.form.phoneNumber = this.phoneNumber
  },
  computed: {
    userUpdated () {
      const obj = {}
      if (this.form.email !== this.email) obj.email = this.form.email
      if (this.form.displayName !== this.displayName) obj.displayName = this.form.displayName
      if (this.form.phoneNumber !== this.phoneNumber) obj.phoneNumber = this.form.phoneNumber
      return obj
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';
.form {
  margin-top: 0;
  width: 90%;
  box-shadow: none;
}
.form-row {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 0;
  .button-w-icon {
    font-size: 12px;
  }
}

</style>


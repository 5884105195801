<template>
  <div id="outerContainerFooter" class="outerContainer">
    <div id="footerBtns" class="innerContainer">
      <router-link class="visitor-btn" to="/">GALLERY</router-link>
      <router-link class="visitor-btn" to="/manifesto">MANIFESTO</router-link>
      <router-link class="visitor-btn" to="/contact">CONTACT</router-link>
      <small>&copy; Copyright {{ year }}, mimi.ro</small>
    </div>
  </div>
</template>

<script>
export default {
  name: 'footerNav',
  data () {
    return {
      year: new Date().getFullYear()
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/styles/variables.scss';
#outerContainerFooter {
  @include shadowSmall;
  background-color: $colorMimiFooter;
}
#footerBtns {
  display: flex;
  justify-content: center;
  align-items: center;
  a, small {
    color: $colorMimiInnerContent;
    text-decoration: none;
    padding: 10px;
    letter-spacing: 1px;
  }
  small {
    @media only screen and (max-width: 700px) {
      display: none;
    }
  }
}
</style>

<template>
  <section class="form">
      <h1 class="center-h">
        Orders and Messages
      </h1>
      <br />
      <ul>
        <li v-for="message in messages" :key="message.date.seconds" class="form-row zebra">
          <img :src="message.painting.imageThumb" loading="lazy" />
          <ul class="message-details">
            <br />
            <li>
              <span v-if="message.type === 'contact'">
                Message about your work named
              </span>
              <span v-else>
                Order for your work
              </span>
              <strong>"{{ message.painting.name }}"</strong>
              with id: {{ message.painting.id}},
              priced: {{ message.painting.price.value }} {{ message.painting.price.currency }}
              <span v-if="message.painting.isFramed">, framed</span>
              <span v-else>, not framed.</span>
            </li>
            <br />
            <li>
              <ul>
                <li>
                  <h2>
                    <span v-if="message.type === 'contact'">Message</span>
                    <span v-else>Order</span>
                    details
                  </h2>
                </li>
                <li>Name: {{ message.client.name }}</li>
                <li>Email: {{ message.client.email }}</li>
                <li>Phone: {{ message.client.phone }}</li>
                <li v-if="message.type === 'contact'">Message text:
                  <strong>{{ message.client.message }}</strong>
                </li>
                <li>Date: {{ getTime(message.date) }}</li>
              </ul>
            </li>
            <li>
              <buttonCustom @click.native="markAsRead(message.id, message.isRead)"
                :button="message.isRead ? 'neutral' : 'edit'"
                :icon="'confirm'"
                :text="message.isRead ? 'Mark as unread' : 'Mark as read'"/>
              <buttonCustom @click.native="remove(message.id)"
              :button="'delete'"
              :icon="'delete'"
              :text="'Delete Message'"/>
            </li>
          </ul>
        </li>
      </ul>
      <delete-modal v-if="messageToDelete" @close="messageToDelete = null" @confirmDelete="removeItem">
        <template v-slot:header>Delete this message?</template>
      </delete-modal>
  </section>
</template>

<script>
import { db } from '@/firebaseInit'
import { actions } from '@/store'
import DeleteModal from '@/components/DeleteModal'

export default {
  components: {
    'delete-modal': DeleteModal
  },
  data () {
    return {
      messageToDelete: null
    }
  },
  methods: {
    getTime (timestamp) {
    let date = new Date(timestamp.seconds*1000)
    let year = date.getFullYear()
    let months_arr = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
    let month = months_arr[date.getMonth()]
    let day = date.getDate()
    let hours = date.getHours()
    let minutes = "0" + date.getMinutes()

    let convdataTime = `${day}-${month}-${year} ${hours}:${minutes.substr(-2)}`
    return convdataTime
    },
    markAsRead(id, isRead) {
      let dbRef = this.userIsAdmin ? db.collection('admins') : db.collection('users')
      let docRef = dbRef.doc(this.userUid).collection('messages').doc(id)

      docRef.update({
        isRead: !isRead
      }).then(() => {
        actions.getMessages()
      }).catch(err => console.log(err))
    },
    remove(id) {
      this.messageToDelete = id
    },
    removeItem () {
      let dbRef = this.userIsAdmin ? db.collection('admins') : db.collection('users')
      let docRef = dbRef.doc(this.userUid).collection('messages').doc(this.messageToDelete)
      docRef.delete().then(() => {
        this.messageToDelete = null
        actions.getMessages()
      }).catch(err => console.log(err))
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';
.form {
  margin-top: 0;
  width: 90%;
  box-shadow: none;
}
.form-row {
  display: flex;
  justify-content: space-between;
  margin: 0;
  @media only screen and (max-width: 740px) {
    flex-direction: column;
    align-items: center;
  }
  ul {
    padding: 10px;
  }
  img {
    width: 250px;
    height: 100%;
  }
  .message-details {
    li {
      margin-bottom: 10px;
    }
  }
  .button-w-icon {
    font-size: 12px;
  }
}

</style>


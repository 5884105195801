import { firebase } from '@firebase/app'
import '@firebase/firestore'
import 'firebase/auth'

var firebaseConfig = {
  apiKey: 'AIzaSyCVpyKSnrQsO8dtRQwLHsohqEBxkFfWmds',
  authDomain: 'pictura-360b3.firebaseapp.com',
  databaseURL: 'https://pictura-360b3.firebaseio.com',
  projectId: 'pictura-360b3',
  storageBucket: 'pictura-360b3.appspot.com',
  messagingSenderId: '286255808948',
  appId: '1:286255808948:web:5779117cc74faa692ef2e1',
  measurementId: 'G-YR4232MTL7'
}
// Initialize Firebase
firebase.initializeApp(firebaseConfig)

export const db = firebase.firestore()
export const auth = firebase.auth()
export const fb = firebase

auth.setPersistence(firebase.auth.Auth.Persistence.SESSION)

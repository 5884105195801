<template>
  <div>
    <h1 class="center-h">
      <buttonCustom @click.native="addNewUser" :button="'add'" :icon="'add'" :text="'Add New User'"  />
      <span v-if="userPaintings.length">Or edit one:</span>
    </h1>
    <ul class="users-list">
      <li v-for="aUser in activeUsers" :key="aUser.email" class="user-row zebra">
        <div>
          <p>
            <span style="font-weight: bold">{{ aUser.email }}: </span>
            <span v-if="aUser.hasSignedUp">Utilizator {{ aUser.displayName}} inregistrat, </span>
            <span v-else>Utilizator neinregistrat, </span>
            <span v-if="aUser.isAllowed">cu acces permis, </span>
            <span v-else>cu acces interzis, </span>
            <span v-if="aUser.isDisplayed">cu lucrari afisate.</span>
            <span v-else>cu lucrari neafisate.</span>
          </p>
          <br />
          <p>
            <buttonCustom @click.native="editUser(aUser)" :button="'edit'" :icon="'edit'" :text="'Edit'"  />
            <buttonCustom @click.native="deleteUser(aUser)" :button="'delete'" :icon="'delete'" :text="'Delete'"  />
          </p>
        </div>
      </li>
    </ul>

    <user-modal v-if="showUserModal"
      :currentUser="currentEditedUser"
      @close="closeUserModal" />

    <delete-modal v-if="showDeleteModal" @close="showDeleteModal = false; userToDelete = null" @confirmDelete="removeItem">
      <template v-slot:header>Are you sure you want to delete and block user: {{ userToDelete.email }}?</template>
    </delete-modal>
  </div>
</template>

<script>
import { db } from '@/firebaseInit'
import DeleteModal from '@/components/DeleteModal'
import userAdminModal from '@/components/userAdminModal'

export default {
  components: {
    'delete-modal': DeleteModal,
    'user-modal': userAdminModal
  },
  data () {
    return {
      activeUsers: [],
      showDeleteModal: false,
      showUserModal: false,
      userToDelete: '',
      currentEditedUser: null
    }
  },
  created () {
    this.getUsers()
  },
  methods: {
    getUsers () {
      db.collection('users')
        .get()
        .then((querySnapshot) => {
          const result = []
          querySnapshot.forEach(doc => {
            const obj = doc.data()
            obj.id = doc.id
            if (!obj.isDeleted) {
              result.push(obj)
            }
          })
          this.activeUsers = result
        })
        .catch(error => console.log('Error getting document:', error))
    },
    addNewUser () {
      this.currentEditedUser = this.getNewUser()
      this.showUserModal = true
    },
    editUser (user) {
      this.currentEditedUser = user
      this.showUserModal = true
    },
    deleteUser (item) {
      this.userToDelete = item
      this.showDeleteModal = true
    },
    removeItem () {
      if (this.userToDelete.hasSignedUp) {
        // if signed up, delete all paintings, if any, from db and images; also auth
        const userPaintings = this.allPaintings.filter(el => el.userUid === this.userToDelete.id)
        if (userPaintings.length) {
          const deleteQuery = db.collection('paintings').where('userUid', '==', this.userToDelete.id)
          deleteQuery
            .get()
            .then(querySnapshot => {
              querySnapshot.forEach(doc => {
                if (doc.exists) {
                  console.log(doc)
                  const fileToDelete = doc.id
                  const thumbToDelete = doc.id + '_thumb'
                  const files = [fileToDelete, thumbToDelete]

                  const promises = []
                  for (const file of files) {
                    promises.push(this.deleteFileAsPromise(file)) // delete images in storage
                  }

                  Promise.all(promises).then(() => {
                    doc.ref.delete() // delete painting in db
                  }).catch(err => console.log(err))
                }
              })
            })
            .then(() => {
              this.blockFromUsersDb(this.userToDelete.id) // delete user in db
              console.log('deleted')
              this.showDeleteModal = false
              this.getUsers()
            })
        } else {
          console.log('no paintingsd')
          this.blockFromUsersDb(this.userToDelete.id) // delete user in db
          console.log('deleted')
          this.showDeleteModal = false
          this.getUsers()
        }
      } else {
        // not signed up, just delete from users db
        this.blockFromUsersDb(this.userToDelete.email)
        this.getUsers()
      }
    },
    blockFromUsersDb (user) {
      const userRef = db.collection('users').doc(user)
      return userRef.update({
        isAllowed: false,
        isDisplayed: false,
        isDeleted: true
      }).then(() => {
        console.log('user was blocked')
      })
        .catch(err => {
          console.log(err)
        })
    },
    closeUserModal () {
      this.showUserModal = false
      this.currentEditedUser = null
      this.getUsers()
    },
    getNewUser () {
      return {
        // authorname is set by the user at sign up
        email: null,
        isAllowed: true,
        isDisplayed: true,
        hasSignedUp: false
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.users-list {
  width: 90%;
  min-width: 300px;
  margin: 50px auto;
  p button {
    font-size: 10px;
    padding: 10px;
  }
}
.user-row {
  list-style-type: none;
  padding: 1em;
  text-align: left;
  span {
    font-size: 20px;
  }
}

</style>

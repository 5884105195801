<template>
  <div class="modalBackground">
    <div class="modalContent">
      <form id="userForm" @submit.prevent="validate">
        <h1 v-if="isNewUser">Add new user</h1>
        <h1 v-else>Editing {{ currentEditedUser.email }}</h1>
        <div v-if="isNewUser" class="form-row">
          <fieldset>
            <label for="useremail">New User Email:</label>
            <input type="text" name="notes" v-model="currentEditedUser.email" placeholder="Add valid email here"/>
            <transition name="fade">
              <div class="error" v-if="errors.email">{{ errors.email }}</div>
            </transition>
          </fieldset>
        </div>
        <div v-if="!isNewUser" class="form-row">
          <fieldset>
            <label for="isDisplayed">Show work </label>
            <select id="isDisplayed" name="isDisplayed" v-model="currentEditedUser.isDisplayed">
              <option disabled value="">Please select one</option>
              <option :value="true">Show</option>
              <option :value="false">Hide</option>
            </select>
          </fieldset>
        </div>
        <div v-if="!isNewUser" class="form-row">
          <fieldset>
            <label for="allowAccess">Access </label>
            <select id="allowAccess" name="allowAccess" v-model="currentEditedUser.isAllowed">
            <option disabled value="">Please select one</option>
            <option :value="true">Allow</option>
            <option :value="false">Deny</option>
          </select>
          </fieldset>
        </div>

        <div class="form-buttons">
         <buttonCustom @click.native="onCancel" :button="'cancel'" :icon="'cancel'" :text="'Cancel'"  />
          <buttonCustom type="submit" @click.native="saveUser" :button="'save'" :icon="'save'" :text="'Save User'"  />
        </div>
        <buttonCustom @click.native="onCancel" :button="'close'" />
      </form>
    </div>
  </div>
</template>

<script>
import { db } from '@/firebaseInit'

export default {
  props: {
    currentUser: Object
  },
  data () {
    return {
      currentEditedUser: {
        email: null,
        isAllowed: true,
        isDisplayed: true
      },
      errors: {
        email: null
      },
      isNewUser: !this.currentUser.email
    }
  },
  created () {
    this.currentEditedUser = { ...this.currentUser }
  },
  methods: {
    validate () {
      if (this.isNewUser) {
      // form validation
        this.errors.email = null
        if (!this.currentEditedUser.email) {
          this.errors.email = 'Email required'
          return false
        }
        if (this.currentEditedUser.email && !this.validEmail(this.currentEditedUser.email)) {
          this.errors.email = 'Valid email please'
          return false
        }
      }
      this.saveUser()
    },
    saveUser () {
      console.log(this.currentEditedUser, 12123424)
      if (this.currentEditedUser.id) {
        // user has been authenticated, just update some props
        db.collection('users').doc(this.currentEditedUser.id).update({
          isDisplayed: this.currentEditedUser.isDisplayed,
          isAllowed: this.currentEditedUser.isAllowed
        })
          .then(() => {
            console.log('Document successfully written!')
          })
          .catch(function (error) {
            console.error('Error writing document: ', error)
          })
          .then(() => {
            this.$emit('close')
          })
      } else {
        // create or update user based on the email
        db.collection('users').doc(this.currentEditedUser.email).set({ ...this.currentEditedUser })
          .then(() => {
            console.log('Document successfully written!')
          })
          .catch(function (error) {
            console.error('Error writing document: ', error)
          })
          .then(() => {
            this.$emit('close')
          })
      }
    },
    onCancel () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';

.modalContent {
  height: auto !important;
  @include whiteBackground;
  border-radius: 4px;
  padding: 70px;
  width: auto;
  margin-left: auto;
  margin-right: auto;
}

#userForm {
  margin: 10px auto;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-around;
  overflow-y: auto;
  min-height: 280px;
  & h1 {
    font-size: 20px;
    margin: 0;
  }
  & * {
    flex: 1 0 auto;
  }
  & .form-row * {
    font-size: 18px;
  }
  & .form-buttons {
    flex: 0 0 80px;
  }
  .form-buttons button {
    font-size: 10px;
  }
}

</style>


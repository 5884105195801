<template>
  <section class="form">
      <h1 class="center-h">
        Contact Forms
      </h1>
      <br />
      <ul>
        <li v-for="contact in contacts" :key="contact.date.seconds" class="contact-row zebra">
          <ul class="contact-details">
            <li>
              <ul>
                <li>Name: {{ contact.name }}</li>
                <li>Email: {{ contact.email }}</li>
                <li>Phone: {{ contact.phone }}</li>
                <li>Occasion: {{ contact.occasion }}</li>
                <li>Id: {{ contact.id }}</li>
                <li>Message: {{ contact.message }}</li>
                <li>Date: {{ getTime(contact.date) }}</li>
              </ul>
            </li>
            <li>
              <buttonCustom @click.native="markAsRead(contact.id, contact.isRead)"
                :button="contact.isRead ? 'neutral' : 'edit'"
                :icon="'confirm'"
                :text="contact.isRead ? 'Mark as unread' : 'Mark as read'"/>
              <buttonCustom @click.native="remove(contact.id)"
              :button="'delete'"
              :icon="'delete'"
              :text="'Delete contact'"/>
            </li>
          </ul>
        </li>
      </ul>
      <delete-modal v-if="contactToDelete" @close="contactToDelete = null" @confirmDelete="removeItem">
        <template v-slot:header>Delete this contact?</template>
      </delete-modal>
  </section>
</template>

<script>
import { db } from '@/firebaseInit'
import { actions } from '@/store'
import DeleteModal from '@/components/DeleteModal'

export default {
  components: {
    'delete-modal': DeleteModal
  },
  data () {
    return {
      contactToDelete: null
    }
  },
  methods: {
    getTime (timestamp) {
    let date = new Date(timestamp.seconds*1000)
    let year = date.getFullYear()
    let months_arr = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
    let month = months_arr[date.getMonth()]
    let day = date.getDate()
    let hours = date.getHours()
    let minutes = "0" + date.getMinutes()

    let convdataTime = `${day}-${month}-${year} ${hours}:${minutes.substr(-2)}`
    return convdataTime
    },
    markAsRead(id, isRead) {
      let docRef = db.collection('contacts').doc(id)

      docRef.update({
        isRead: !isRead
      }).then(() => {
        actions.getContacts()
      }).catch(err => console.log(err))
    },
    remove(id) {
      this.contactToDelete = id
    },
    removeItem () {
      let dbRef = db.collection('contacts')
      let docRef = dbRef.doc(this.contactToDelete)
      docRef.delete().then(() => {
        this.contactToDelete = null
        actions.getContacts()
      }).catch(err => console.log(err))
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';
.form {
  margin-top: 0;
  width: 90%;
  box-shadow: none;
}
.contact-row {
  ul {
    padding: 10px;
  }
  .contact-details {
    li {
      margin-bottom: 10px;
      width: 100%;
    }
  }
  .button-w-icon {
    font-size: 12px;
  }
}

</style>


<template>
  <section>
    <form novalidate="true"
      id="signupForm"
      @submit.prevent="signup"
      class="form"
      >
      <h1>
        Sign Up
      </h1>
      <div class="form-row">
        <label for="email">Email</label>
        <input v-model="form.email" type="email" name="email" id="email" class="form-rowInput" :placeholder="placeholder.email"/>
        <transition name="fade">
          <div class="error" v-if="errors.email">{{ errors.email }}</div>
        </transition>
      </div>
      <div class="form-row">
        <label for="displayName">Display name</label>
        <input v-model="form.displayName" type="text" name="displayName" id="displayName" class="form-rowInput" :placeholder="placeholder.displayName"/>
        <transition name="fade">
          <div class="error" v-if="errors.displayName">{{ errors.displayName }}</div>
        </transition>
      </div>
      <div class="form-row">
        <label for="phoneNumber">Phone number</label>
        <input v-model="form.phoneNumber" type="number" name="phoneNumber" id="phoneNumber" class="form-rowInput" :placeholder="placeholder.phoneNumber"/>
        <transition name="fade">
          <div class="error" v-if="errors.phoneNumber">{{ errors.phoneNumber }}</div>
        </transition>
      </div>
      <div class="form-row">
        <label for="password">Password</label>
        <input v-model="form.password" type="password" name="password" id="password" class="form-rowInput" :placeholder="placeholder.password"/>
        <transition name="fade">
          <div class="error" v-if="errors.password">{{ errors.password }}</div>
        </transition>
      </div>
      <div class="form-row">
        <label for="passwordCheck">Re-type password</label>
        <input v-model="form.passwordCheck" type="password" name="passwordCheck" id="passwordCheck" class="form-rowInput" autocomplete="new-password" :placeholder="placeholder.passwordCheck" />
        <transition name="fade">
          <div class="error" v-if="errors.passwordCheck">{{ errors.passwordCheck }}</div>
        </transition>
      </div>
      <button type="reset" class="form-buttons" @click="onCancel">Reset</button>
      <button type="submit" class="form-buttons">Sign up</button>
    </form>
  </section>
</template>

<script>
import { auth, db } from '@/firebaseInit'

export default {
  data () {
    return {
      form: {
        email: '',
        displayName: '',
        phoneNumber: '',
        password: '',
        passwordCheck: ''
      },
      errors: {
        email: null,
        displayName: null,
        phoneNumber: null,
        password: null,
        passwordCheck: null
      },
      placeholder: {
        email: 'the email address that you gave to mimi',
        displayName: 'will be displayed as author name',
        phoneNumber: 'will be displayed and used by clients to contact you',
        password: 'used for login',
        passwordCheck: 'must be the same password'
      },
      tempUser: null,
      isAllowed: true
    }
  },
  methods: {
    signup () {
      this.errors = {
        email: null,
        displayName: null,
        password: null,
        passwordCheck: null
      }
      if (!this.form.email) {
        this.errors.email = 'Email required'
      }
      if (!this.form.displayName) {
        this.errors.displayName = 'Display Name Required'
      }
      if (!this.form.password) {
        this.errors.password = 'Password required'
      }
      if (!this.form.passwordCheck) {
        this.errors.passwordCheck = 'Password required here, too'
      }
      if (!this.validEmail(this.form.email)) {
        this.errors.email = 'Valid email please'
      }
      if (this.form.passwordCheck !== this.form.password) {
        this.errors.passwordCheck = 'The passwords must be identical'
      }
      const formHasNoErrors = !Object.values(this.errors).some(x => (x !== null && x !== ''))
      if (formHasNoErrors) {
        // only allow users in uses db and with isAllowed === true
        db.collection('users').doc(this.form.email).get()
          .then((snapshot) => {
            if (!snapshot.exists) {
              this.isAllowed = false
              this.errors.email = 'Email address not allowed yet for signup'
            } else {
              this.isAllowed = snapshot.data().isAllowed
              if (!this.isAllowed) {
                this.errors.email = 'Not allowed'
                console.log('Not allowed')
              }
            }
          })
          .catch(err => console.log(err))
          .then(() => {
            if (this.isAllowed) {
              // create auth user
              auth.createUserWithEmailAndPassword(this.form.email, this.form.password)
                .then(res => {
                  this.tempUser = res.user
                  console.log('Auth created', this.tempUser)
                }).catch(error => {
                  this.errors.email = error.message
                  return false
                }).then(() => {
                  // update in users db
                  const usersDb = db.collection('users')
                  usersDb.doc(this.form.email).get().then((doc) => {
                    if (doc && doc.exists) {
                      const data = doc.data()
                      data.email = this.form.email || null
                      data.displayName = this.form.displayName || null
                      data.phoneNumber = this.form.phoneNumber || null
                      data.hasSignedUp = true
                      // saves data to 'name'
                      usersDb.doc(this.tempUser.uid).set(data).then(() => {
                        // deletes the old document
                        usersDb.doc(this.form.email).delete()
                      })
                    }
                  }).catch(err => {
                    console.log(err)
                  })
                })
                .then(() => {
                  console.log('OK')
                  this.$router.replace('/login')
                })
            } else {
              console.log('Not authenticated')
            }
          })
      }
    },
    onCancel () {
      this.form = {
        email: '',
        password: ''
      }
    }
  }
}
</script>

<template>
  <section id="login">
    <form novalidate="true"
      id="loginForm"
      @submit.prevent="login"
      class="form"
      >
      <h1>
        Login
      </h1>
      <div class="form-row">
        <label for="email">Email</label>
        <input v-model="form.email" type="email" name="email" id="email" class="form-rowInput"/>
        <transition name="fade">
          <div class="error" v-if="errors.email">{{ errors.email }}</div>
        </transition>
      </div>
      <div class="form-row">
        <label for="password">Password</label>
        <input v-model="form.password" type="password" name="password" id="password" class="form-rowInput"/>
        <transition name="fade">
          <div class="error" v-if="errors.password">{{ errors.password }}</div>
        </transition>
      </div>
      <div class="form-row form-row-buttons">
        <button type="reset" class="form-buttons cancel-button" @click="onCancel" :disabled="!isResatable">Reset</button>
        <button type="submit" class="form-buttons add-button" :disabled="isDisabled">Login</button>
      </div>
    </form>
  </section>
</template>

<script>
import { auth, db } from '@/firebaseInit'

export default {
  data () {
    return {
      form: {
        email: '',
        password: ''
      },
      errors: {
        email: null,
        password: null
      }
    }
  },
  watch: {
    email (newVal) {
      // redirect to mypaintings after login
      if (newVal) {
        this.$router.replace('/mypaintings')
      }
    }
  },
  computed: {
    isDisabled () {
      return !this.form.email || !this.form.password
    },
    isResatable () {
      return this.form.email || this.form.password
    }
  },
  methods: {
    login () {
      this.errors = {
        email: null,
        password: null
      }
      if (!this.form.email) {
        this.errors.email = 'Email required'
      }
      if (!this.form.password) {
        this.errors.password = 'Password required'
      }
      if (this.form.email && !this.validEmail(this.form.email)) {
        this.errors.email = 'Valid email please'
      }
      const formHasNoErrors = !Object.values(this.errors).some(x => (x !== null && x !== ''))
      if (formHasNoErrors) {
        console.log('checking user')
        const userQuery = db.collection('users').where('email', '==', this.form.email)
        userQuery
          .get()
          .then((snapshot) => {
            if (snapshot.docs.length) {
              snapshot.docs.forEach(user => {
                if (user.data().isAllowed) {
                  auth.signInWithEmailAndPassword(this.form.email, this.form.password)
                    .then(response => console.log('logged in', response))
                    .catch(err => {
                      this.errors.password = err
                    })
                  this.onCancel()
                } else {
                  this.errors.email = 'Not allowed'
                }
              })
            } else {
            // check maybe user is in admins
              console.log('checking admin')
              const adminQuery = db.collection('admins').where('email', '==', this.form.email)
              adminQuery
                .get()
                .then(snapshot => {
                  if (snapshot.docs.length && snapshot.docs[0].exists) {
                    auth.signInWithEmailAndPassword(this.form.email, this.form.password)
                      .then(response => console.log('logged in', response))
                      .catch(err => {
                        this.errors.password = err
                      })
                    this.onCancel()
                  } else {
                    this.errors.email = 'User not found'
                  }
                })
                .catch(err => {
                  this.errors.email = err
                })
            }
          })
      }
    },
    onCancel () {
      this.form = {
        email: '',
        password: ''
      }
      this.errors = {
        email: null,
        password: null
      }
    },
    validEmail: function (email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(email)
    }
  }
}
</script>

<template>
  <nav class="nav">
    <router-link class="nav-item" to="/" tag="button">Home</router-link>
    <router-link class="nav-item" to="/mypaintings" tag="button">My Works</router-link>
    <router-link class="nav-item" to="/sortworks" tag="button">Sort works</router-link>
    <router-link class="nav-item" to="/messages" tag="button">Messages
      <span class="error" v-if="userUid && unread > 0">({{unread}})</span>
    </router-link>
    <router-link class="nav-item" to="/formcontacts" tag="button" v-if="userIsAdmin">Contacts
      <span class="error" v-if="unreadContacts > 0">({{unreadContacts}})</span>
    </router-link>
<!--     <router-link class="nav-item" to="/admin" v-if="userIsAdmin" tag="button">Users</router-link> -->
    <router-link class="nav-item" to="/profile" tag="button">Profile</router-link>
    <button class="edit-buttons nav-item" @click="logOut" aria-label="Log out">Log out</button>
  </nav>
</template>

<script>
import { auth } from '@/firebaseInit'

export default {
  data () {
    return {
      showOptions: false
    }
  },
  computed: {
    unread () {
      return this.messages.filter(el => !el.isRead).length
    },
    unreadContacts () {
      return this.contacts.filter(el => !el.isRead).length
    }
  },
  methods: {
    logOut () {
      auth.signOut().catch(function (error) {
        var errorCode = error.code
        var errorMessage = error.message
        if (errorMessage) console.log(`Error code: ${errorCode} with message: ${errorMessage}`)
      })
        .then(() => {
          if (this.$route.path !== '/') {
            this.$router.replace('/')
          }
        })
    }
  }
}
</script>

<style scoped>
.error {
  color: orange;
  font-family: Arial, sans;
}
</style>
<template>
  <section id="galleryAdmin" class="form">
    <div>
      <h1 class="center-h">
        <buttonCustom @click.native="addNew" :button="'add'" :icon="'add'" :text="'Add New Painting'"  style="margin-bottom: 20px;"/>
        <span v-if="filteredPaintings.length">Or edit one of your own works:</span>
      </h1>
      <div class="filtersContainer">
        <h3>Find paintings:</h3>
        <div class="filters">
          <div class="filter">
            <label for="name">Name</label>
            <input type="text" id="name" name="name" v-model="filters.name">
          </div>
          <div class="filter">
            <label for="id">Id</label>
            <input type="text" id="id" name="id" v-model="filters.id">
          </div>
          <div class="filter">
            <label for="year">Year</label>
            <select id="year" v-model="filters.year">
              <option v-for="option in yearOptions" :key="option">
                {{ option }}
              </option>
            </select>
          </div>
          <div class="filter">
            <label for="isSold">Is Sold?</label>
            <select id="isSold" v-model="filters.isSold">
              <option v-for="option in soldOptions" :value="option.value" :key="option.text">
              {{ option.text }}
              </option>
            </select>
          </div>
          <div class="filter">
            <label for="isPriceShown">Is Price Shown?</label>
            <select id="isPriceShown" v-model="filters.isPriceShown">
              <option v-for="option in priceShownOptions" :value="option.value" :key="option.text">
              {{ option.text }}
              </option>
            </select>
          </div>
          <div class="filter">
            <label for="isShown">Is Painting Shown?</label>
            <select id="isShown" v-model="filters.isPaintingShown">
              <option v-for="option in showOptions" :value="option.value" :key="option.text">
              {{ option.text }}
              </option>
            </select>
          </div>
        </div>
        <h3>
          Total shown: {{ filteredPaintings.length }}
          <buttonCustom @click.native="resetFilters" :button="'cancel'" :icon="'cancel'" :text="'Reset filters'"  style="margin-bottom: 20px;"/>
        </h3>
      </div>
      <div v-if="filteredPaintings.length">
        <table v-if="filteredPaintings && filteredPaintings.length" id="orderTable">
        <tr class="tbHeadingRow zebra">
          <th class="tbHeadingCell">Name</th>
          <th class="tbHeadingCell previewCell">Preview</th>
          <th class="tbHeadingCell">Price</th>
          <th class="tbHeadingCell">Year</th>
          <th class="tbHeadingCell">Is sold?</th>
          <th class="tbHeadingCell">Edit</th>
<!--           <th class="tbHeadingCell">Delete</th> -->
        </tr>
        <tr v-for="painting in filteredPaintings" :key="painting.id" class="zebra">
          <td style="padding-left: 20px;">
            {{ painting.name }}
            <br />
            <br />
            Id: {{ painting.id }}
          </td>
          <td class="previewCell">
            <img :src="painting.imageThumb + '.jpg'" class="preview">
          </td>
          <td>
            {{ painting.price.value }} {{ painting.price.currency }}
          </td>
          <td>
            {{ painting.year }}
          </td>
          <td>
            <span v-if="painting.isSold">Yes</span>
            <span v-else>No</span>
          </td>
          <td>
            <buttonCustom @click.native="editExisting(painting)" :button="'edit'" :icon="'edit'" :text="'Edit'"  />
          </td>
          <td>
<!--             <buttonCustom @click.native="deletePainting(painting)" :button="'delete'" :icon="'delete'" :text="'Delete'"  /> -->
          </td>
        </tr>
      </table>
 <!--      <button @click="reorderPaintingsPositions">Test</button> -->
      </div>
    </div>
    <div>
      <painting-edit-modal v-if="isEditMode" :paintingToEdit="painting" @closeModal="closeModal" />
      <delete-modal v-if="paintingToDelete" @close="paintingToDelete = null" @confirmDelete="removeItem">
        <template v-slot:header>Delete this painting: <br/>{{ paintingToDelete.name }}?</template>
      </delete-modal>
    </div>
  </section>
</template>

<script>
import PaintingEditModal from '@/components/PaintingEditModal'
import DeleteModal from '@/components/DeleteModal'
import 'firebase/storage'
import { db } from '@/firebaseInit'
import { actions } from '@/store'

export default {
  components: {
    'painting-edit-modal': PaintingEditModal,
    'delete-modal': DeleteModal
  },
  data () {
    return {
      painting: null,
      isEditMode: false,
      paintingToDelete: null,
      filters: this.getNewFilters(),
      soldOptions: [
        { text: 'sold', value: true },
        { text: 'not sold', value: false},
        { text: 'all', value: null}
      ],
      showOptions: [
        { text: 'show painting', value: true },
        { text: 'hide painting', value: false},
        { text: 'all', value: null}
      ],
      yearOptions: [
        '2009', '2010', '2011', '2012', '2013', '2014', '2015', '2016', '2017', '2018', '2019', '2020', '2021', '2022', '2023'
      ],
      priceShownOptions: [
        { text: 'show price', value: true },
        { text: 'hide price', value: false },
        { text: 'all', value: null }
      ]
    }
  },
  computed: {
    filteredPaintings () {
      let hasFilter = Object.values(this.filters).some(x => (x !== null && x !== ''))
      if (!hasFilter) {
        return this.userPaintings
      } else {
        let filtered = [...this.userPaintings]
        for (const [key, value] of  Object.entries(this.filters)) {
          if (this.filters[key] !== null && this.filters[key] !== '') {
            if (key === 'name' || key === 'id') {
              let upp = value.toUpperCase()
              filtered = [...filtered].filter(el => el[key].toUpperCase().includes(upp))
            } else if (key === 'isPriceShown') {
              filtered = [...filtered].filter(el => el.price[key] === value)
            } else {
              filtered = [...filtered].filter(el => el[key] === value)
            }
          }
        }
        return filtered
      }
    }
  },
  methods: {
    addNew () {
      this.painting = null
      this.isEditMode = true
    },
    editExisting (userPainting) {
      this.painting = userPainting
      this.isEditMode = true
    },
    deletePainting (userPainting) {
      this.paintingToDelete = userPainting
    },
    removeItem () {
      const fileToDelete = this.paintingToDelete.imageRef
      const thumbToDelete = this.paintingToDelete.imageRef + '_thumb'
      const files = [fileToDelete, thumbToDelete]

      const promises = []
      for (const file of files) {
        promises.push(this.deleteFileAsPromise(file))
      }
      Promise.all(promises).then(() => {
        db.collection('paintings').doc(fileToDelete).delete().then(() => {
          this.paintingToDelete = null
          this.resortPaintingsIndexes()
          actions.initializePaintings()
          // reset positions for all paintings
        }).catch(err => console.log(err))
      })
    },
    reorderPaintingsPositions () {
      for (let i = this.userPaintings.length - 1; i >= 0; i-- ) {
        console.log(this.userPaintings[i].position, i)
      }
    },
    closeModal () {
      this.painting = null
      this.paintingToDelete = null
      this.isEditMode = false
    },
    getNewFilters () {
      return {
        year: null,
        isSold: null,
        name: null,
        id: null,
        isPriceShown: null,
        isPaintingShown: null
      }
    },
    resetFilters () {
      this.filters = this.getNewFilters()
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/styles/variables.scss';

.filtersContainer {
  width: 100%;
  margin: 10px 0 20px 0;
  padding-top: 20px;
  background-color: $colorMimiInnerContent;
  @include beigeBackground;
    h3 {
      margin: 20px 0;
    }
    .filters {
      display: flex;
      flex-wrap: wrap;
      .filter {
        flex: 1 0 auto;
        margin-top: 5px;
        padding: 15px;
        label {
          margin-right: 5px;
        }
        * {
          font-size: 16px;
        }
      }
  }
}

</style>

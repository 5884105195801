<template>
  <div>
    <navbar v-if="$route.name !== 'login' && !!userUid"/>
    <visitor-navbar v-if="!userUid" />
    <div id="app" class="container">
      <transition name="fade" mode="out-in">
        <router-view/>
      </transition>
    </div>
    <footer-nav />
  </div>
</template>

<script>
import Navbar from '@/components/Navbar'
import VisitorNavbar from '@/components/VisitorNavbar'
import FooterNav from '@/components/Footer'
import { actions } from '@/store'
import '@/styles/global.scss'

export default {
  name: 'App',
  components: {
    navbar: Navbar,
    'visitor-navbar': VisitorNavbar,
    'footer-nav': FooterNav
  },
  created () {
    actions.initializePaintings()
    actions.authCheckUser()
  }
}
</script>

<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}
</style>

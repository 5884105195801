import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { store } from '@/store'
import firebase from 'firebase/app'
import ButtonCustom from '@/components/ButtonCustom'

Vue.component('buttonCustom', ButtonCustom )

Vue.config.productionTip = false

Vue.mixin({
  computed: {
    paintings () {
      return store.paintings || null
    },
    allPaintings () {
      return store.allPaintings || null
    },
    userUid () {
      return store.user.uid || null
    },
    email () {
      return store.user.email || null
    },
    displayName () {
      return store.user.displayName || null
    },
    phoneNumber () {
      return store.user.phoneNumber || null
    },
    userIsAdmin () {
      return store.user.isAdmin || null
    },
    userPaintings () {
      return store.allPaintings.filter(el => el.userUid === store.user.uid) || null
    },
    messages () {
      return store.user.messages || null
    },
    contacts () {
      return store.contacts || null
    }
  },
  methods: {
    validEmail: function (email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(email)
    },
    deleteFileAsPromise (file) {
      return new Promise((resolve) => {
        const storageRef = firebase.storage().ref()
        const fileRef = storageRef.child(file)

        fileRef.delete().then(() => {
          resolve()
        }).catch(err => {
          console.log(err)
        })
      })
    }
  }
})

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
